import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

const saveHighScore = async (gamename, phone, level, score, with_history) => {
  try {
    const response = await fetch(
      "https://us-central1-kinet-web.cloudfunctions.net/saveHighScore",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gamename: gamename,
          phone: phone,
          level: level,
          score: score,
          with_history: with_history,
        }),
      }
    );
    const res = await response.json();
    console.log("\x1b[36mfdafdfa", res)
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
  }
};

const subscribeUserWithoutPayment = async (phone) => {
  try {
    const response = await fetch(
      "https://us-central1-kinet-web.cloudfunctions.net/subscribe",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phone }),
      }
    );
    const res = await response.json();
    console.log("User Subscription Data", res)
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};


const loginUserFirebase = async (phone) => {
  try {
    const response = await fetch(
      "https://us-central1-kinet-web.cloudfunctions.net/loginUserWithFirestore",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phone }),
      }
    );
    const res = await response.json();
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

const changeUserDeviceAPICall = async (phone, otp) => {
  try {
    const response = await fetch(
      "https://us-central1-kinet-web.cloudfunctions.net/registerDevice",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phone, otp: otp }),
      }
    );
    const res = await response.json();
    if (res?.error) {
      return null;
    } else if (res?.userData) {
      return res.userData;
    } else {
      return null;
    }
  } catch (e) {
    console.warn(e);
    return null;
  }
};

const subscribeUserWithoutPaymentAction = createAsyncThunk(
  "user/subscribeUserWithoutPaymentAction",
  async (data) => {
    const users = await subscribeUserWithoutPayment(data.value);
    if (users) {
      console.log(users)
      return users;
    } else {
      return null;
    }
  }
);

const saveHighScoreAction = createAsyncThunk(
  "user/saveHighScoreAction",
  async (data) => {
    // console.log( "\x1b[36muser data going", data)
    const users = await saveHighScore(
      data.gamename,
      data.phone,
      data.level,
      data.score,
      data.with_history
    );
    // console.log(user)
    if (users) {
      return { tournaments: users.tournaments, user: users.user };
    } else {
      return null;
    }
  }
);

const changeDevice = createAsyncThunk("user/changeDevice", async (data) => {
  console.log(data);
  const users = await changeUserDeviceAPICall(data.value, data.otp);
  if (users) {
    return users;
  } else {
    return null;
  }
});

const anonymouslySignIn = createAsyncThunk(
  "user/anonymouslySignIn",
  async (data) => {
    const userRequest = await loginUserFirebase(data.value);
    if (userRequest) {
      return userRequest;
    } else {
      return null;
    }
  }
);

const userSlice = createSlice({
  name: "user",

  initialState: {
    loading: false,
    error: false,
    value: null,
    isLogedIn: false,
    acceptTerms: false,
    isSubscribed: false,
    profile: null,
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload,
      state.isLogedIn = true
    },
    logout: (state) => {
      signOut(auth);
      state.value = null;
      state.profile = null;
      state.error = false;
      state.loading = false;
      state.isLogedIn = false;
      state.isSubscribed = false;
    },
    acceptTerms: (state) => {
      state.acceptTerms = !state.acceptTerms;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(anonymouslySignIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(anonymouslySignIn.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload) {
        if (
          action.payload?.success == true &&
          action.payload?.loggedIn == false &&
          action.payload?.uid
        ) {
          state.value = action.payload;
          state.loading = false;
          state.isLogedIn = true;
          state.isSubscribed = action?.payload?.isSubscribed
            ? action?.payload?.isSubscribed
            : false;
          state.error = "";
        } else {
          state.value = null;
          state.loading = false;
          state.isLogedIn = false;
          state.isSubscribed = false;
          state.error = "User Is Already Logged In in another device";
        }
      } else {
        state.value = null;
        state.isSubscribed = false;
        state.isLogedIn = false;
        state.loading = false;
        state.error = "Unknown Error";
      }
    });
    builder.addCase(anonymouslySignIn.rejected, (state, action) => {
      state.loading = false;
      state.value = null;
      state.isLogedIn = false;
      state.isSubscribed = false;
      state.error = action.error.message;
    });
    builder.addCase(changeDevice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeDevice.fulfilled, (state, action) => {
      if (action.payload) {
        state.value = action.payload;
        state.isLogedIn = true;
        state.isSubscribed = true;
        state.loading = false;
      }
    });
    builder.addCase(changeDevice.rejected, (state, action) => {
      state.loading = false;
      state.value = null;
      state.isLogedIn = false;
      state.isSubscribed = false;
      state.error = action.error.message;
    });
    builder.addCase(subscribeUserWithoutPaymentAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      subscribeUserWithoutPaymentAction.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.value = action.payload;
          state.isLogedIn = true;
          state.isSubscribed = false;
          state.loading = false;
        }
      }
    );
    builder.addCase(
      subscribeUserWithoutPaymentAction.rejected,
      (state, action) => {
        state.loading = false;
        state.value = null;
        state.isLogedIn = false;
        state.isSubscribed = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(saveHighScoreAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveHighScoreAction.fulfilled, (state, action) => {
      if (action.payload?.user) {
        state.value = action.payload.user;
        state.isLogedIn = true;
        state.isSubscribed = true;
        state.loading = false;
      }
    });
    builder.addCase(saveHighScoreAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const anonymouslyLogin = anonymouslySignIn;
export const subAction = subscribeUserWithoutPaymentAction;
export const saveHighScoreActions = saveHighScoreAction;
export const changePlayerDevice = changeDevice;
export const userAction = userSlice.actions;
export const { login } = userSlice.actions;
export default userSlice.reducer;
