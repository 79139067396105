import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import ContactUs from "./Pages/ContactUs";
import MainLayout from './MainLayout'
import GameList from './Pages/GameList'
import Carousal from "../Components/SingleCarousal";
import Personalized from '../Navigation/Pages/Personalized'
import Game from '../Components/Game/GameContainer'
import GameLayout from "./GameRoute";
import Privecy from './Pages/Privecy'
import TermsOfService from './Pages/TermsOfService'


export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Carousal />} />
      <Route path="games/" element={<GameList />} />
      <Route path="auth/" element={<Personalized />} />
      <Route path="privacy/" element={<Privecy />} />
      <Route path="terms-and-service/" element={<TermsOfService />} />
      <Route path="contact-us/" element={<ContactUs />} />
    </Route>, <Route path="/" element={<GameLayout />}><Route path="/playing" element={<Game />} /></Route>]

  )
);
