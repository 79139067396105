import { useState, useEffect, useCallback } from "react";

const useGameScore = (user, game) => {
  const [scores, setScore] = useState({});
  const [isLoadingSet, setIsLoading] = useState(false);
  const getGameScore = useCallback(async (data) => {
    try{
        let headersList = {
          "Content-Type": "application/json"
        }
        
        let bodyContent = JSON.stringify({
          "phone":user,
          "game": game,
        });
        
        let response = await fetch(
          `${process.env.REACT_APP_API_URL_FUNCTION}usergamescore`
          , { 
          method: "POST",
          body: bodyContent,
          headers: headersList
        });
        
        let res = await response.text();
        setScore(res)
        console.log("New Score is ", res)
        setIsLoading(true)
        return res
      } catch(e){
        console.log(e)
      }
  });

  useEffect(() => {
    getGameScore();
  }, []);


  return { scores, isLoadingSet };
};

export default useGameScore;