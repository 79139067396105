import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAeYCOTqkhTQ6ZZpD75ysM5ygYGetXvnHI",
  authDomain: "kinet-web.firebaseapp.com",
  databaseURL:
    "https://kinet-web-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kinet-web",
  storageBucket: "kinet-web.appspot.com",
  messagingSenderId: "270613774005",
  appId: "1:270613774005:web:4dfdc6bff7700e0576c8fc",
  measurementId: "G-8K6KK7H819",
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app); // Add this line to initialize Firestore



//
export { auth, app, database, firestore};

// export const firestore = firebase.firestore();
