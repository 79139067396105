import React from "react";
import GamePlay from "./GamePlay";
import { useLocation } from 'react-router-dom';

export default function Game() {
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const url = queryParams.get('url');
  const withHistory = queryParams.get('with_history') === 'true';
  const gameType = queryParams.get('paymentType')

  return (
      <GamePlay url={url} paymentType={gameType} gameName={name} withHistory={withHistory}  />
  );
}
