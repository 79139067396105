import { useState, useEffect } from 'react';

const useCheckSubscription = (phone) => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [isNotSubscribed, setIsNotSubscribed] = useState(null);
  const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfSubscribed = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_FUNCTION}checkIfSubscribed`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone }),
          }
        );

        const data = await response.json();
        setIsSubscribed(!data.isSubscribed);
        setIsNotSubscribed(!data.isSubscribed);
        // console.log("isSubscribed", data)
      } catch (e) {
        setIsNotLoggedIn(true);
        setIsNotSubscribed(true);
      } finally {
        setLoading(false);
      }
    };

    if (phone) {
      checkIfSubscribed();
    } else {
      setLoading(false);
    }
  }, [phone]);

  return { isSubscribed, isNotSubscribed, isNotLoggedIn, loading };
};

export default useCheckSubscription;
