import { useState, useEffect, useCallback } from "react";
import { getDocs, collection } from "firebase/firestore";
import {firestore } from "../firebase";

const sortTournament = (a, b) => {
  if (a.index < b.index) return -1;
};

const useGames = () => {
  const [games, setGames] = useState([]);
  const [isLoadingSet, setIsLoading] = useState(true);
  const getGames = useCallback(async (data) => {
    try {
      let gameList = [];
      const querySnapshot = await getDocs(collection(firestore, "games"));
      querySnapshot.forEach((doc) => {
        let temp = doc.data();
        if (temp.active) {
          gameList.push(temp);
        }
      });
      let gamesData = gameList.sort(sortTournament);
      setGames(gamesData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    getGames();
  }, []);


  return { games, isLoadingSet };
};

export default useGames;