import PhoneScrean from "../../Components/Auth/LogedInScrean";
import { IconsLoader } from "../../imports/icon";
import { useSelector } from "react-redux";
import React from "react";
import one from "../../assets/images/damalogin.jpg";
import Profile from "../../Components/Auth/Profile";

export default function Personalized() {
  const isLogedIn = useSelector((state) => state.user.isLogedIn);
  if (isLogedIn) {
    return <Profile />;
  } else {
    return (
      <div
        className="flex max-h-[90vh] md:min-h-[93.3vh] min-h-[90vh]   w-[100%] md:w-[100%] bg-[#242C37]"
        style={{
          backgroundImage: `url(${one})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="right-0 md:visible invisible absolute z-0 w-[60%]"></div>
        <div className="md:w-[40%] max-h-[100vh] w-[100%] px-5 md:px-12  bg-[#242C37] flex flex-col justify-center  ">
          <div className="px-5">
            <IconsLoader icon="logosimple" className={"w-[50px] xl:w-[10vh]"}  height={60} />
          </div>
          <PhoneScrean />
        </div>
      </div>
    );
  }
}
