import SingleCarousal from "../Comp/SingleCaraousal";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Carousal() {
  const auth = useSelector((state) => state.user)
  const navigation = useNavigate()
  const routersAuth = () => {
    if (!auth.isLogedIn) {
      navigation({ pathname: '/auth' })
    }
  }
  React.useEffect(() => {
    routersAuth()
  }, [])
  return <SingleCarousal />
}
