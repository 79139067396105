import React from "react";
import { useSelector } from "react-redux";
import { IconsLoader } from "../../imports/icon";
import {Link} from 'react-router-dom';

export default function Description() {
  const language = useSelector((state) => state.lang.language);
  console.log(language)
  return (
    <div className="bottom-0">
      <div className="flex flex-col justify-center items-center pb-5 border-t-[1px] pt-5 mx-20 bg-[white]">
        <IconsLoader icon="logosimple" width={50} height={50} />
        <p className="text-[#242C37] text-sm md:text-md text-center w-[100%] md:w-[70%] font-milkb">
          {language?.rules}
        </p>
      </div>
      <div className="bg-[#242C37] py-5">
        <div className="flex flex-col justify-center items-center">
          <a href="/contact-us/"  className="text-[#FE9902] font-[200] py-2 font-roboto border-b-[0.5px] mb-2 w-[20vw] text-center ">
            {language?.contactus}
          </a>
          <div className="flex flex-row justify-between w-[15%] pb-2">
            <a href="https://www.facebook.com/kinetgames" target="_blank">
              <IconsLoader icon="face" width={30} height={30} />
            </a>
            <a href="https://www.instagram.com/kinetgames" target="_blank">
              <IconsLoader icon="insta" width={30} height={30} />
            </a>
            <a
              href="https://www.tiktok.com/@kinet_games_official"
              target="_blank"
            >
              <IconsLoader icon="tiktokwhite" width={30} height={30} />
            </a>
          </div>

          <Link
            href="/home/privacy"
            className="text-[#FE9902] font-[200] py-2 font-roboto"
          >
            {language?.privecyPolicy}
          </Link>
          <Link
            href="/home/terms-and-service"
            className="text-[#FE9902] font-[200] py-2 font-roboto"
          >
            {language?.termandservice}
          </Link>
        </div>
      </div>
    </div>
  );
}
