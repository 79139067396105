import React from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IconsLoader } from "../../imports/icon";


export default function Footer() {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const pathname = useLocation();
  const route = useNavigate();
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    console.log("resizing", screenWidth)
    if (typeof window !== "undefined") {

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }
  }, [screenWidth]);
  const navigate = (item) => {

        route(item.pathname, undefined, { shallow: true });

  };
  return (
    <footer className="bg-white  w-[100%] mt-5  fixed  bottom-0">
      {screenWidth < 640 ? (
        <div className=" w-[100%] flex flex-row  visible sm:invisible">
          {[
            {
              id: 1,
              name: "home-fill",
              inactive: "home-empity",
              pathname: "/",
            },
            {
              id: 2,
              name: "game-filed",
              inactive: "game-unfilled",
              pathname: "/games/",
            },
            {
              id: 3,
              name: "profile-active",
              inactive: "profileunfilled",
              pathname: "/auth/",
            },
          ].map((item, index) => (
            <a
              onClick={() => navigate(item)}
              className={`w-[33.3%] flex justify-center cursor-pointer  border-r-2 py-1 ${
                pathname.pathname == item.pathname ? "bg-[#FE9902] " : "bg-[white]"
              } `}
              key={index}
            >
              <IconsLoader
                width={100}
                height={80}
                className="w-[30px] h-[30px]"
                icon={pathname.pathname == item.pathname ? item.name : item.inactive}
              />
            </a>
          ))}
        </div>
      ) : null}
    </footer>
  );
}
