// src/components/IconsLoader.js

import React from 'react';

const IconsLoader = ({ icon, width, height, className, color }) => {
  const iconPath = require(`../assets/icons/${icon}.svg`);

  return (
    <img
      alt={icon}
      src={iconPath}
      width={width}
      height={height}
      className={className}
      style={{ color }}
    />
  );
};

export { IconsLoader };
