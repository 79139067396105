import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";


export default function Page() {
  const [phone, setPhone] = useState("");
  const [termsofservice, setTermsOfService] = useState("");
  const user = useSelector((state) => state.user.value);
  // const dispatch = useDispatch()
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (phone, term) => {
    try {
      let bodyContent = JSON.stringify({
        phone: phone,
        termsofservice: term,
      });
      let headersList = {
        "Content-Type": "application/json",
      };
      let response = await fetch(
        "https://us-central1-kinet-web.cloudfunctions.net/changeTermsOfService",
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );

      console.log("here is a response", response);
      let res = await response.json();
      setTermsOfService(res.term);

      setMessage(response);
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred");
    }
  };

  const getTerm = async (phone) => {
    try {
      let bodyContent = JSON.stringify({
        phone: phone,
      });
      let headersList = {
        "Content-Type": "application/json",
      };
      let response = await fetch(
        "https://us-central1-kinet-web.cloudfunctions.net/changeTermsOfService",
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );

      let res = await response.json();
      console.log("response", res);

      setTermsOfService(res.term);
      setMessage(response);
    } catch (error) {
      console.log(error);
      setError(error.response?.data?.error || "An error occurred");
    }
  };

  React.useEffect(() => {
    getTerm(user?.phone);
  }, []);
  return (
    // <ReduxProvider>
    <div className="bg-white pt-20 px-12 text-black  pb-10 -mb-10">
      <h1> KINET STORE - TERMS OF SERVICE</h1>
      <span className="font-light italic">
        {" "}
        Effective Date: December 13, 2023 Welcome to the Kinet Store!
      </span>
      <p className="mt-5">
        These Terms of Service ("Terms") govern your use of our website and
        services provided by Qene Technologies PLC ("we," "us," or "our")
        available at https://kinet.store ("Services"). By using our Services,
        you agree to comply with these Terms. Please read them carefully.
      </p>
      <ul>
        <li>
          <h1 className="font-bold mt-5">
            1. User Registration and Eligibility:
          </h1>
          <li>
            {" "}
            a. To access certain features, users must register with accurate and
            valid information.
          </li>
          <li>
            {" "}
            b. Inaccurate or false information may result in disqualification or
            account suspension.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5">2. Subscription Details:</h1>
          <li>
            {" "}
            a. These Games require a subscription. You will receive a 3-day free
            trial. After the trial period, your subscription will automatically
            renew at a rate of 2 ETB per day. The sole fee applicable is a daily
            service fee of 2 ETB, granting access to all our games.
          </li>
          <li>
            {" "}
            b. Payment is processed through Ethio Telecom, and users can
            unsubscribe by sending "stop" to 9871.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5">3. Data Collection:</h1>
          <li>
            {" "}
            a. We collect information during registration and gameplay as
            outlined in our Privacy Policy.
          </li>
          <li>
            {" "}
            b. Users consent to the collection and processing of personal
            information.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5"> 4. Gameplay and Fair Play:</h1>
          <li>
            {" "}
            a. Cheating or exploiting game mechanics will result in immediate
            disqualification.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5"> 5. Code of Conduct:</h1>
          <li>
            {" "}
            a. Participants are expected to maintain a respectful and
            sportsmanlike demeanor.
          </li>
          <li>
            {" "}
            b. Harassment, hate speech, or inappropriate behavior may lead to
            disqualification.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5"> 6. Technical Requirements:</h1>
          <li>
            {" "}
            a. Participants are responsible for meeting hardware and internet
            connection requirements.
          </li>
          <li>
            {" "}
            b. Technical issues on the participant's end will not be grounds for
            match interruption or rescheduling.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5">
            {" "}
            7. Streaming and Content Creation:{" "}
          </h1>
          <li>
            {" "}
            a. Participants are encouraged to create content related to their
            experience.
          </li>
          <li>
            {" "}
            b. Sharing sensitive or private information about other participants
            without consent is prohibited.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5">
            {" "}
            8. Rule Changes and Notification:{" "}
          </h1>
          <li>
            {" "}
            a. Organizers reserve the right to modify any rules structure due to
            unforeseen circumstances.
          </li>
          <li>
            {" "}
            b. Participants will be promptly notified of any rule changes.
          </li>
        </li>
        <li>
          <h1 className="font-bold mt-5"> 9. Contact Information: </h1>
          <li>
            {" "}
            a. For inquiries or concerns related to our Services, contact
            Contact@qenetech.com. Changes to Terms of Service: We may update
            these Terms, and any material changes will be communicated on the
            website. Thank you for using Kinet Store! If you have any questions
            or concerns, please reach out to us at Contact@qenetech.com.
          </li>
        </li>
      </ul>
      {user ? (
        <div className="flex flex-row justify-end">
          {termsofservice == "ACCEPT" ? (
            <button
              onClick={() =>
                handleSubmit(
                  user.phone,
                  "DECLINE"
                )
              }
              className="bg-[#701c1c] p-5 m-5 text-white"
            >
              You Have Accepted The Terms of Service
            </button>
          ) : (
            <button
              onClick={() =>
                handleSubmit(
                  user.phone,
                  'ACCEPT'
                )
              }
              className="bg-[#701c1c] p-5 m-5 text-white"
            >
              Please Accept the Terms Of Service
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <h1 className="shadow-[#701c1c] text-xl font-milkr cursor-pointer">
            Please Login
          </h1>
        </div>
      )}
    </div>
    // </ReduxProvider>
  );
}
