import React from "react";
import { Provider } from "react-redux";
import store from ".";
import { PersistGate } from "redux-persist/integration/react";

import { persistStore } from "redux-persist";

let persist = persistStore(store); // persist the store

export default function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        {children}
      </PersistGate>
    </Provider>
  );
}
