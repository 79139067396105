

const useEventsListenersGTAG = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

const newDataIs = () => {
  window.location.href = "sms:9871?body=ok";
};

export { useEventsListenersGTAG, newDataIs };
