import React from "react";

export default function Privecy() {
  return (
  <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <iframe
        src="https://www.termsfeed.com/live/06b9c37f-e78c-4198-b592-28f8b057d0aa" // Change this to the desired external URL
        style={{ border: 'none', width: '100%', height: '100%' }}
        title="Privacy Policy"
      />
    </div>
  );
  // <div className="pt-20 px-20 text-black bg-white min-h-[100vh] pb-10 -mb-10">
  //   <h1>QENE TECHNOLOGIES PLC - PRIVACY POLICY</h1>
  //   <h5 className="mt-2 italic">Effective Date: December 1, 2023</h5>
  //   <p className="mt-2 text-sm">
  //     Welcome to Kinet Store! Qene Technologies PLC ("we," "us," or "our")
  //     values your privacy and is committed to protecting your personal
  //     information. This Privacy Policy outlines how we collect, use, and share
  //     your information when you use our services ("Services") available at
  //     https://kinet.store/. Please take a moment to review this policy and
  //     understand your privacy rights and choi fces.
  //   </p>
  //   <h1 className="text-black font-bold mt-4">Summary of Key Points</h1>
  //   <div>
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Personal Information Collection:</h1>
  //       <p className="ml-5">
  //         We collect information you voluntarily provide during registration,
  //         including phone numbers.
  //       </p>
  //     </div>
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Sensitive Personal Information:</h1>
  //       <p className="ml-5">
  //         We process your information to provide, improve, and administer our
  //         Services, ensuring security, and complying with legal obligations.
  //       </p>
  //     </div>
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Privacy Rights:</h1>
  //       <p className="ml-5">
  //         Depending on your location, you may have certain privacy rights, and
  //         you can exercise them through data subject access requests.
  //       </p>
  //     </div>{" "}
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Cookies and Tracking Technologies:</h1>
  //       <p className="ml-5">
  //         We use cookies and tracking technologies for analytics and
  //         personalized experiences.
  //       </p>
  //     </div>{" "}
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Data Retention:</h1>
  //       <p className="ml-5">
  //         We retain your information for as long as necessary, complying with
  //         legal requirements.
  //       </p>
  //     </div>{" "}
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Privacy Notice Updates:</h1>
  //       <p className="ml-5">
  //         We may update this notice to stay compliant with laws, and any
  //         material changes will be communicated.
  //       </p>
  //     </div>{" "}
  //     <div className="flex flex-row">
  //       <h1 className="font-bold">Contact Us</h1>
  //       <p className="ml-5">
  //         For privacy-related inquiries, contact us at Contact@qenetech.com.
  //       </p>
  //     </div>
  //   </div>
  //   <div>
  //     <h1 className="font-bold mt-4">Gameplay and Data Collection</h1>
  //     <li className="font-bold py-2 px-5">
  //       User Registration:
  //       <span className="font-light">
  //         Users can register using their phone number. Social media login is
  //         not supported.
  //       </span>
  //     </li>
  //     <li className="font-bold py-2 px-5">
  //       Subscription Detail{" "}
  //       <span className="font-light">
  //         Subscription-based with a 3-day free trial. Payment is processed
  //         through Ethio Telecom. Users can unsubscribe by sending "stop" to
  //         9871.
  //       </span>
  //     </li>
  //     <li className="font-bold py-2 px-5">
  //       Data Collection Methods:{" "}
  //       <span className="font-light">
  //         Through web analytics tools, custom tracking code, in-game
  //         analytics, cookies, and geolocation services.
  //       </span>
  //     </li>
  //   </div>
  //   <div>
  //     <h1 className="font-bold pt-2">Data Security Measures</h1>
  //     <p className="text-sm pt-2">
  //       We implement security logs, encryption, and secure authentication
  //       methods to protect user data.
  //     </p>
  //     <h1 className="font-bold pt-2">Data Usage and Sharing</h1>
  //     <p className="text-sm pt-2">
  //       User data is used for service improvement, personalization, user
  //       experience optimization, marketing, security and fraud prevention,
  //       product development, and customer support. Data may be shared for
  //       billing, process improvement, and issue tracking.
  //     </p>
  //     <h1 className="font-bold pt-2">User Rights and Choices</h1>
  //     <p className="text-sm pt-2">
  //       Users can exercise their rights through data subject access requests
  //       and opt-out of certain data collection or marketing communications.
  //     </p>
  //     <h1 className="font-bold pt-2">Data Breach Notification:</h1>
  //     <p className="text-sm pt-2">
  //       We are committed to promptly notifying users in the event of a data
  //       breach, outlining the steps taken to mitigate the breach and informing
  //       affected users.
  //     </p>
  //     <h1 className="font-bold pt-2">User Education:</h1>
  //     <p className="text-sm pt-2">
  //       We encourage users to practice good online privacy habits, including
  //       creating strong passwords, recognizing phishing attempts, and securing
  //       their devices.
  //     </p>
  //     <h1 className="font-bold pt-2">User Consent:</h1>
  //     <p className="text-sm pt-2">
  //       By using our services, users explicitly consent to the terms outlined
  //       in this privacy policy, including the collection and processing of
  //       personal information.
  //     </p>
  //     <h1 className="font-bold pt-2">Data Accuracy:</h1>
  //     <p className="text-sm pt-2">
  //       Users are responsible for ensuring the accuracy of the personal
  //       information they provide. We provide mechanisms for users to update
  //       their information.
  //     </p>
  //     <h1 className="font-bold pt-2">
  //       Cross-Border Data Transfers (if applicable):
  //     </h1>
  //     <p className="text-sm pt-2">
  //       If cross-border data transfers become necessary, we will inform users
  //       of the countries involved and the measures in place to protect data
  //       during transfer, ensuring compliance with data protection laws.
  //     </p>
  //     <h1 className="font-bold pt-2">User Feedback:</h1>
  //     <p className="text-sm pt-2">
  //       We welcome user feedback on privacy-related concerns. For questions or
  //       issues, please reach out to us at Contact@qenetech.com.
  //     </p>
  //     <h1 className="font-bold pt-2">Children's Privacy</h1>
  //     <p className="text-sm pt-2">
  //       Our game website is intended for users under the age of 13, and we
  //       take measures to comply with relevant regulations.
  //     </p>
  //     <h1 className="font-bold pt-2">Data Retention</h1>
  //     <p className="text-sm pt-2">
  //       User data is retained for as long as needed.
  //     </p>
  //     <h1 className="font-bold pt-2">International Data Transfer</h1>
  //     <p className="text-sm pt-2">
  //       User data is not transferred internationally.
  //     </p>
  //     <h1 className="font-bold pt-2">Changes to Privacy Policy</h1>
  //     <p className="text-sm pt-2">
  //       Users will be notified of changes on the website.
  //     </p>
  //     <h1 className="font-bold pt-2">Contact Information</h1>
  //     <p className="text-sm pt-2">
  //       For privacy-related inquiries, contact us at Contact@qenetech.com.
  //     </p>
  //   </div>
  // </div>
  // );
}
