import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Components/Adons/Header'
import "react-phone-number-input/style.css";
import ReduxProvider from '../redux/store/ReduxProvider';
import Footer from '../Components/Adons/Footer'



const GameLayout = () => {
    // useEffect(() => {
    //     if ("serviceWorker" in navigator) {
    //         window.addEventListener("load", () => {
    //             navigator.serviceWorker
    //                 .register("/service-worker.js")
    //                 .then((registerd) => {
    //                     console.log("serviceWorker Registered", registerd.scope);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //         });
    //     }
    // }, []);

    return (
        <ReduxProvider>
            <section className="min-h-[100vh] max-h-[100vh] h-auto w-[100%] bg-white">
                <Outlet />
            </section>
        </ReduxProvider>
    );
};

export default GameLayout;
