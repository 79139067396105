import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../Components/Adons/Header'
import "react-phone-number-input/style.css";
import ReduxProvider from '../redux/store/ReduxProvider';
import Footer from '../Components/Adons/Footer'



const MainLayout = () => {


    return (
        <ReduxProvider>
            <section className="min-h-[100vh] max-h-[100vh] h-auto w-[100%] bg-white">
                <Header />
                <Outlet />
                <Footer />
            </section>
        </ReduxProvider>
    );
};

export default MainLayout;
