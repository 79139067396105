// context/EnvironmentContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
    const [isWebViewApp, setIsWebViewApp] = useState(false);


    useEffect(() => {
        function isWebView() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
            const isInStandaloneMode = window.matchMedia(
              "(display-mode: standalone)"
            ).matches;
      
            const webViewIndicators = ["macle-simulator"];
      
            return (
              webViewIndicators.some((indicator) => userAgent.includes(indicator)) ||
              (isIOS && !isInStandaloneMode) ||
              userAgent.includes("wv") ||
              userAgent.includes("WebView") ||
              (window.matchMedia &&
                window.matchMedia("(display-mode: standalone)").matches) ||
              (window.navigator && window.navigator.standalone)
            );
          }
      
          if (isWebView()) {
            console.log("This is a WebView");
            setIsWebViewApp(true)
          } else {
            console.log("This is a normal browser");
            setIsWebViewApp(false)
          }
    }, []);

    return (
        <EnvironmentContext.Provider value={{ isWebViewApp }}>
            {children}
        </EnvironmentContext.Provider>
    );
};

export const useEnvironment = () => useContext(EnvironmentContext);
