import React, { useState } from "react";
import { firestore } from "../../firebase";
import { IconsLoader } from "../../imports/icon";
import two from "../../assets/images/profileBG.png";
// const db = firestore();
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';


const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    try {
        const contactDocRef = doc(firestore, 'contactus', email);
        const docSnap = await getDoc(contactDocRef);
  
        if (docSnap.exists()) {
          // If the document exists, update the message field
          await setDoc(contactDocRef, {
            message,
            timestamp: serverTimestamp()
          }, { merge: true });
        } else {
          // If the document doesn't exist, create a new document
          await setDoc(contactDocRef, {
            email,
            message,
            timestamp: serverTimestamp()
          });
        }
      setSuccess(true);
      setEmail("");
      setMessage("");
    } catch (err) {
      setError("Failed to send message. Please try again.");
    }
  };

  return (
    <div className=" flex flex-col justify-center items-center min-w-[100vw] min-h-[100vh]">
      <div
        style={{ backgroundImage: `url(${two})`, backgroundSize: "cover" }}
        className="absolute top-0 flex flex-row min-h-[35vh] bg-[#7b7c7b] w-[100%]"
      ></div>
      <h1 className="text-2xl font-milkb text-[gray] py-5 ">Contact Us</h1>
      <form
        className="flex flex-col items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="my-5 ">
          <input
            className="border-[2px] outline-none px-5 mx-5 rounded-md border-[#FE9902] py-2 w-[50vw] shadow-md "
            type="email"
            placeholder="example@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="my-5 ">
          <textarea
            placeholder="I am sending ths message to inform you ...."
            className="border-[2px] outline-none px-5 mx-5 rounded-md border-[#FE9902] py-2 w-[50vw] shadow-lg"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className=" w-[50vw] md: max-h-[50px] absolute bottom-[18%]  bg-gradient-to-r from-[#FE9902] via-[#FE9902] to-[#FE9902] shadow-xl rounded-[5px] overflow-hidden"
        >
          <h1 className=" text-white font-milkb pt-5 absolute left-[40%] p-5">
            {" "}
            Send
          </h1>

          <div className="flex flex-row  px-5">
            <IconsLoader icon="playmask" />
          </div>
        </button>
      </form>
      {success && <p className="font-milkb text-[gray]">Thank you for contacting us!</p>}
      {error && <p className="font-milkb text-[gray]">{error}</p>}
    </div>
  );
};

export default ContactUs;
